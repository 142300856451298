<template>
  <div class="tool">
    <div class="card operate">
      <div class="card-header"><span class="card-title">运营工具</span></div>
      <van-grid class="tools" clickable :border="false">
        <van-grid-item
          @click="clickGridItem(tool)"
          v-for="tool in operateTools"
          :key="tool.name"
          :icon="tool.icon"
          :text="tool.name"
          :to="tool.path || ''"
        />
      </van-grid>
    </div>
    <!-- <div class="card">
      <div class="card-header"><span class="card-title">管理工具</span></div>
      <van-grid class="tools" clickable :border="false">
        <van-grid-item
          v-for="tool in managermentTools"
          :key="tool.name"
          :icon="tool.icon"
          :text="tool.name"
          :to="tool.path"
        />
      </van-grid>
    </div> -->
  </div>
</template>

<script>
import { Grid, GridItem, Toast } from 'vant'
import { reactive, toRefs } from 'vue'
export default {
  name: 'tool',
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  setup(props) {
    const data = reactive({
      operateTools: [
        // { name: '客户群发', icon: 'envelop-o', path: '/mass-texting' },
        // { name: '话术库', icon: 'notes-o', path: '/speechcraft' },
        { name: '客户欢迎语', icon: 'smile-o', path: '/welcome' },
        // { name: '优惠券', icon: 'discount', path: '/coupon' },
        { name: '素材库', icon: 'photo-o', path: '/material' },
        {
          name: '清除缓存',
          icon: require(`@/assets/clear.png`),
          clear: true
        }
      ],
      managermentTools: [
        { name: '微客学院', icon: 'shop-o', path: '/mass-texting' }
      ]
    })

    return {
      ...toRefs(data)
    }
  },
  methods: {
    clickGridItem(item = {}) {
      if (item.clear) {
        localStorage.clear()
        sessionStorage.clear()
        Toast.success('清除成功')
      }
      console.log('clickGridItem', item.clear)
    }
  }
}
</script>

<style lang="less" scoped>
.tool {
  box-sizing: border-box;
  padding: 20px;
  .card {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    &-header {
      padding: 20px;
      border-bottom: 1px solid #eaeaea;
    }
    &-title {
      border-bottom: 4px solid #1989fa;
    }
  }
  .operate {
    margin-bottom: 20px;
  }
}
</style>
